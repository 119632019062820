<template>
  <Html :lang="locale" :dir="head.htmlAttrs.dir">
    <Head>
      <Title>{{ title }}</Title>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>
    <Body>
      <div class="layout default grid w-dvw">
        <WorkspaceSwitcher />
        <LayoutNavigation style="grid-area: sidebar" />
        <div id="header-n-body" :key="subdomain" class="@container relative" style="grid-area: content">
          <div class="layout-main relative grid">
            <slot />
          </div>
        </div>
      </div>
      <ModalsContainer />
    </Body>
  </Html>
</template>

<script setup lang="ts">
  import { ModalsContainer } from "vue-final-modal";

  const { t, locale } = useI18n();
  const route = useRoute();

  const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: "id",
    addSeoAttributes: true,
  });
  const title = computed(() => (route.meta.title ? t(route.meta.title) : "Homerunner"));

  const workspaceStore = useWorkspacesStore();
  const { subdomain } = storeToRefs(workspaceStore);
</script>
