<template>
  <div>
    <div ref="trigger" class="w-full" @click="toggleDropdown">
      <slot :open="showDropdown" />
    </div>
    <Dropdown
      ref="dropdown"
      v-model:open="showDropdown"
      :toggle-element="trigger"
      :calculate-position="calculatePosition"
    >
      <div class="dropdown__content max-w-[312px] p-1.5">
        <div class="flex flex-col gap-1">
          <LayoutNavigationWorkspaceEntry
            v-for="(workspace, index) in workspaces"
            :key="workspace.subdomain || workspace.account.id"
            :workspace="workspace"
            :index="index"
          />
        </div>
        <template v-if="accountIsBusiness">
          <Divider />
          <div class="text-body-sm text-tertiary px-3">
            <i18n-t :keypath="accountLevelText" tag="p">
              <template #organisation>
                <span>{{ accountName }}</span>
              </template>
              <template #carrier>
                <span>{{ pickUpCarrier }}</span>
              </template>
            </i18n-t>
          </div>
        </template>
        <Divider v-if="dropdownItems.length" />
        <DropdownItem
          v-for="item in dropdownItems"
          :key="item.label"
          :to="item.href"
          @click="item.onClick && item.onClick()"
        >
          {{ item.label }}
        </DropdownItem>
        <Divider />
        <div>
          <DropdownItem :to="{ name: RouteName.logout }">
            {{ t("logout_from_organisation", { organisation: accountName }, 99) }}
          </DropdownItem>
        </div>
      </div>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
  import { RouteName } from "~/types/routes";

  defineOptions({
    name: "WorkspaceDropdown",
  });

  const { hasCapability } = useCapabilities();
  const store = useWorkspacesStore();
  const { accountName, workspaces } = storeToRefs(store);

  const { toggleNavigationBar } = inject("navigationBarOpen");
  const { t } = useI18n();

  const calculatePosition = (cb) => {
    //get main element
    const mainElm = document.querySelector("main")?.getBoundingClientRect();
    if (mainElm) {
      const left = mainElm.left;

      setStyleProperty("top", `1.5rem`);
      setStyleProperty("left", `calc(${left}px + 0.375rem)`);
    }

    if (cb && typeof cb === "function") cb();
  };

  const { dropdown, trigger, showDropdown, toggleDropdown, setStyleProperty } = useDropdown();

  watch(
    () => showDropdown.value,
    (val) => {
      toggleNavigationBar(val);
    }
  );

  const { services } = storeToRefs(useServicesStore());
  const { accountIsBusiness, accountLevel } = useWorkspacesStore();

  const openSettings = () => window.$workspace_settings.open();
  const openInviteUsers = () => {
    window.$inviteusers.open();
  };
  const pickUpCarrier = services.value?.transfer_delivery_method || t("not_available");
  const accountLevelText = computed(() => {
    switch (accountLevel) {
      case "SMV":
        return "account_level.smv.description";
      case "C2C":
        return "account_level.c2c.description";
      case "ENTERPRISE":
        return "account_level.enterprise.description";
      case "SCALE_UP":
        return "account_level.scale_up.description";
      case "DFM":
        return "account_level.dfm.description";
      default:
        return "";
    }
  });

  const dropdownItems = computed(() =>
    [
      {
        condition: accountIsBusiness && hasCapability("account.users.edit"),
        label: t("invite_users_to_organisation", { organisation: accountName.value }),
        onClick: openInviteUsers,
      },
      {
        condition: hasCapability("account.documents") || hasCapability("account.documents.edit"),
        label: t("document", 99),
        href: { name: RouteName.documents },
      },
      {
        condition: hasCapability("account.invoices") || hasCapability("account.invoices.edit"),
        label: t("invoice", 99),
        href: { name: RouteName.invoices },
      },
      {
        condition: hasCapability("account.prices") || hasCapability("account.prices.edit"),
        label: t("prices_overview"),
        href: { name: RouteName.prices },
      },
      {
        condition: hasCapability("account.*"),
        label: t("workspace_settings", 99),
        onClick: openSettings,
      },
    ].filter((item) => item.condition)
  );
</script>
