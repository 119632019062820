<template>
  <div>
    <div ref="trigger" class="w-full" @click="toggleDropdown">
      <slot :open="showDropdown" />
    </div>
    <Dropdown
      ref="dropdown"
      v-model:open="showDropdown"
      :toggle-element="trigger"
      :calculate-position="calculatePosition"
    >
      <div class="flex w-[312px] flex-col p-1.5">
        <div class="flex gap-2.5 self-stretch p-2">
          <AvatarUser size="xs" />
          <div class="text-primary mr-auto flex flex-col overflow-hidden text-sm font-medium">
            <span class="truncate">{{ userName }}</span>
            <span class="text-tertiary truncate text-xs">{{ userEmail }}</span>
          </div>
        </div>
        <DropdownItem @click="openSettings">
          {{ $t("preferences") }}
        </DropdownItem>

        <Divider />
        <div class="flex flex-col">
          <DropdownItem :to="{ name: RouteName.cookies }">
            {{ t("cookie_policy") }}
          </DropdownItem>
        </div>

        <Divider />
        <div class="flex flex-col">
          <DropdownItem :to="{ name: RouteName.logout }">
            {{ t("logout_from_organisation", { organisation: accountName }, 99) }}
          </DropdownItem>
        </div>
      </div>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
  import { RouteName } from "~/types/routes";

  defineOptions({
    name: "UserDropdown",
  });

  const { accountName, userName, userEmail } = storeToRefs(useWorkspacesStore());
  const { t } = useI18n();
  const openSettings = () => window.$user_settings.open();

  const { toggleNavigationBar } = inject("navigationBarOpen");

  const calculatePosition = (cb) => {
    //get main element
    const mainElm = document.querySelector("main")?.getBoundingClientRect();
    if (mainElm) {
      const left = mainElm.left;

      const dropdownHeight = dropdown.value?.height || 0;

      setStyleProperty("top", `calc(100% - ${dropdownHeight}px - 1.5rem)`);
      setStyleProperty("left", `calc(${left}px + 0.375rem)`);
    }
    if (cb && typeof cb === "function") cb();
  };

  const { dropdown, trigger, showDropdown, toggleDropdown, setStyleProperty } = useDropdown();

  watch(
    () => showDropdown.value,
    (val) => {
      toggleNavigationBar(val);
    }
  );
</script>
