<template>
  <LayoutNavigationWorkspaceDropdown v-slot="slotProps">
    <div
      class="dropdown-toggle hover:bg-01 relative flex w-9 cursor-pointer items-center gap-2.5 rounded-[10px] p-1 group-hover/sidebar:w-[190px] group-hover/sidebar:delay-200 group-data-[open=true]/sidebar:w-[190px] group-data-[open=true]/sidebar:delay-200"
      :class="{ 'bg-surface-lvl-00 shadow-lvl-01': slotProps.open }"
    >
      <AvatarOrganisation size="2xs" />
      <div class="text-body-default-heavy text-primary mr-auto truncate">
        {{ accountName }}
      </div>
      <div class="flex items-center">
        <Icon
          class="transition-transform"
          src="bold/CaretDown"
          :size="ComponentSize.sm"
          :class="{
            '-rotate-90 transform': slotProps.open,
          }"
        />
      </div>
    </div>
  </LayoutNavigationWorkspaceDropdown>
</template>

<script setup lang="ts">
  defineOptions({
    name: "LayoutNavigationWorkspace",
  });
  const { accountName } = storeToRefs(useWorkspacesStore());
</script>
